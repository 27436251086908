define('whoisharrison/serializers/blizzard', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.JSONAPISerializer.extend({
		normalizeResponse(store, primaryModelClass, payload, id, requestType) {
			requestType;
			return {
				data: payload.rows.map(info => {
					return {
						id: info.name,
						ranking: info.ranking,
						type: 'blizzard',
						attributes: {
							ranking: info.ranking,
							name: info.name,
							rating: info.rating,
							realmId: info.realmId,
							realmName: info.realmName,
							realmSlug: info.reamlSlug,
							raceId: info.raceId,
							classId: info.classId,
							specId: info.specId,
							factionId: info.factionId,
							genderId: info.genderId,
							seasonWins: info.seasonWins,
							seasonLosses: info.seasonLosses,
							weeklyWins: info.weeklyWins,
							weeklyLosses: info.weeklyLosses
						}
					};
				})
			};
		}
	});
});