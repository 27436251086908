define('whoisharrison/routes/movies/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    model() {
      return this.store.findAll('movie');
    },

    actions: {

      deleteMovie(movie) {
        let confirmation = confirm('Are you sure?');

        if (confirmation) {
          movie.destroyRecord();
        }
      }
    }

  });
});