define('whoisharrison/adapters/nasa', ['exports', 'ember-data', 'whoisharrison/config/environment'], function (exports, _emberData, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const key = _environment.default.nasaKey.id;

	exports.default = _emberData.default.RESTAdapter.extend({
		host: 'https://api.nasa.gov',
		namespace: 'planetary',

		pathForType() {
			return 'apod?api_key=' + key;
		}
	});
});