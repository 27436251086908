define('whoisharrison/routes/websites/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    model() {
      // return this.store.findAll('website');
    }

  });
});