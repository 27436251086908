define('whoisharrison/controllers/blizzard-api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ongoingSearch = false;

  exports.default = Ember.Controller.extend({
    searchTerm: null,
    search: false,

    actions: {
      updateSearchTerm(filterBy) {
        const searchTerm = Ember.get(this, 'filterBy');
        searchTerm;
        if (ongoingSearch) clearTimeout(ongoingSearch);
        ongoingSearch = setTimeout(() => {
          Ember.set(this, 'search', true);
          Ember.set(this, 'searchTerm', filterBy);
        }, 500);
      }
    }
  });
});