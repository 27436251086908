define('whoisharrison/serializers/nasa', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTSerializer.extend({
		normalizeResponse(store, primaryModelClass, payload) {
			console.log(payload);
			return {
				data: {
					id: payload.title,
					type: 'nasa',
					attributes: payload
				}
			};
		}
	});
});