define('whoisharrison/helpers/message-in-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    //this is used to clear selected permission
    compute([messages, selectedMessage]) {
      return messages.includes(selectedMessage);
    }
  });
});