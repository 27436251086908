define('whoisharrison/components/blizz-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let ongoingSearch = false;

  exports.default = Ember.Component.extend({
    searchTerm: null,

    actions: {
      updateSearchTerm(filterBy) {
        const searchTerm = Ember.get(this, 'filterBy');
        searchTerm;
        if (ongoingSearch) clearTimeout(ongoingSearch);
        ongoingSearch = setTimeout(() => {
          Ember.set(this, 'searchTerm', filterBy);
        }, 500);
      }
    }
  });
});