define('whoisharrison/routes/admin/contacts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    setupController(controller, model) {
      controller.set('model', model);
      return this._super(arguments);
    },

    model() {
      const contacts = this.store.findAll('contacts');
      const notes = this.store.findAll('notes');
      const websites = this.store.findAll('website');
      const requests = this.store.findAll('request');
      return Ember.RSVP.hash({
        contacts,
        notes,
        websites,
        requests
      });
    },

    actions: {
      deleteMessage(message) {
        let confirmation = confirm('Are you sure?');

        if (confirmation) {
          message.destroyRecord();
        }
      },

      deleteWebsite(website) {
        let confirmation = confirm('Are you sure?');

        if (confirmation) {
          website.destroyRecord();
        }
      },

      selectedMessage(message) {
        const list = this.controller.get('messages');
        if (list.find(v => v == message)) {
          this.controller.set('messages', list.filter(v => v != message));
        } else {
          this.controller.set('messages', list.concat(message));
        }
      }
    }

  });
});