define('whoisharrison/routes/application', ['exports', 'whoisharrison/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const un = _environment.default.creds.un;
  const pw = _environment.default.creds.pw;

  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function () {
      return this.get('session').fetch().catch(function () {});
    },
    actions: {
      signIn: function (provider) {
        this.get('session').open('firebase', { provider: provider, email: un, password: pw }).then(function (data) {
          console.log(data.currentUser);
        });
      },
      signOut: function () {
        this.get('session').close();
      }
    }
  });
});