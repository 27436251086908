define('whoisharrison/router', ['exports', 'whoisharrison/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('about');
    this.route('contact');
    this.route('portfolio');
    this.route('blizzard-api');
    this.route('nasa-api');
    this.route('weather-api');
    this.route('summer-movies');
    this.route('design');
    this.route('motion');
    this.route('video');
    this.route('hodlers');

    this.route('movies', function () {
      this.route('new');
      this.route('edit', { path: '/:movie_id/edit' });
    });

    this.route('admin', function () {
      this.route('contacts');
      this.route('invitations');
      this.route('edit', { path: '/:website_id/edit' });
      this.route('new');
    });
  });

  exports.default = Router;
});