define('whoisharrison/routes/blizzard-api', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model() {
			const blizzard = this.store.findAll('blizzard');
			return blizzard;
		}
	});
});