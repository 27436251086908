define('whoisharrison/models/blizzard', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const { attr } = _emberData.default;

	exports.default = _emberData.default.Model.extend({
		ranking: attr('number'),
		rating: attr('number'),
		name: attr('string'),
		realmId: attr('number'),
		realmName: attr('string'),
		realmSlug: attr('string'),
		raceId: attr('number'),
		classId: attr('number'),
		specId: attr('number'),
		factionId: attr('number'),
		genderId: attr('number'),
		seasonWins: attr('number'),
		seasonLosses: attr('number'),
		weeklyWins: attr('number'),
		weeklyLosses: attr('number')
	});
});