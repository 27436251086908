define('whoisharrison/components/bs-nav', ['exports', 'whoisharrison/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const un = _environment.default.contact.username;
  const pwd = _environment.default.contact.password;

  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    isShowingModal: false,
    invalid: false,
    isDisabled: true,

    actions: {
      toggleModal: function () {
        this.toggleProperty('isShowingModal');
      },
      login() {
        const name = this.get('name');
        const password = this.get('password');
        if (password == null || password == undefined) {
          this.set('invalid', true);
          return;
        }
        if (name == un && password == pwd) {
          this.set('isShowingModal', false);
          this.get('router').transitionTo('admin.contacts');
        } else {
          this.set('invalid', true);
        }
      }
    }
  });
});