define('whoisharrison/initializers/ember-g-recaptcha', ['exports', 'whoisharrison/config/environment', 'ember-g-recaptcha/configuration'], function (exports, _environment, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-g-recaptcha',

    initialize: function () /* registry */{
      const config = _environment.default.gReCaptcha || {};
      _configuration.default.setProperties(config);
    }
  };
});