define('whoisharrison/models/nasa', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const { attr } = _emberData.default;

	exports.default = _emberData.default.Model.extend({
		media_type: attr('string'),
		date: attr('string'),
		url: attr('string'),
		explanation: attr('string'),
		hdurl: attr('string'),
		title: attr('string')
	});
});