define('whoisharrison/routes/nasa-api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      const nasa = this.store.findAll('nasa');
      return nasa;
    }
  });
});