define('whoisharrison/adapters/blizzard', ['exports', 'ember-data', 'whoisharrison/config/environment'], function (exports, _emberData, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const blizkey = _environment.default.blizKey.id;

	exports.default = _emberData.default.JSONAPIAdapter.extend({
		host: 'https://us.api.blizzard.com',
		namespace: 'wow',

		pathForType() {

			//this isnt running in time. 
			const key = "5224a12924824e0fa5fb5b15e2e44b05";
			const secret = "oaAJu1Sgt5VDTEgvKs5LbGORqEGFupJw";
			fetch(' https://us.battle.net/oauth/token', {
				method: 'POST',
				body: 'grant_type=client_credentials&client_id=' + key + '&client_secret=' + secret,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			}).then(function (resp) {
				// Return the response as JSON
				return resp.json();
			}).then(function (data) {
				const token = data.access_token;
			}).catch(function (err) {
				console.log('something went wrong', err);
			});;

			return 'leaderboard/2v2?locale=en_US&access_token=' + token;
		}

	});
});