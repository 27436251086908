define('whoisharrison/controllers/admin/contacts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    messages: [],
    title: '',
    note: '',
    isShowingModal: false,

    fund: Ember.computed("model.websites.@each.fund", function () {
      const funds = this.get('model.websites').getEach('fund');
      const sum = funds.reduce((a, b) => a + b, 0);
      return sum;
    }),

    percentage: Ember.computed('fund', function () {
      const fund = this.get('fund');
      const percentage = fund / 100000 * 100;
      return percentage;
    }),

    clearForm() {
      this.set('title', '');
      this.set('note', '');
      this.set('messages', []);
    },

    actions: {
      toggleModal: function () {
        this.toggleProperty('isShowingModal');
      },

      sendNote() {
        const messages = this.get('messages');

        if (!confirm("Are you sure you save " + messages.length + " messages to notes?")) return;
        messages.map(newNote => {
          const submittedForm = {
            title: newNote.get('name'),
            email: newNote.get('emailAddress'),
            note: newNote.get('message'),
            timestamp: newNote.get('timestamp')
          };
          const sentNote = this.store.createRecord('notes', submittedForm);
          sentNote.save();
        });
        return this.clearForm();
      },

      deleteSelected() {
        const messages = this.get('messages');
        if (!confirm("Are you sure you want to delete " + messages.length + " messages?")) return;
        messages.map(message => {
          message.destroyRecord();
        });
        return this.clearForm();
      },

      saveNote() {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const day = new Date().getUTCDate();
        const now = new Date();
        const thisMonth = months[now.getMonth()];
        const year = now.getFullYear();

        const submittedForm = {
          title: this.get('title'),
          note: this.get('note'),
          timestamp: thisMonth + " " + day + " " + year
        };

        const newNote = this.store.createRecord('notes', submittedForm);
        newNote.save();
        this.set('isShowingModal', false);
        this.clearForm();
      }
    }
  });
});