define('whoisharrison/components/bs-footer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentYear: 2017,
    init() {
      this._super(...arguments);
      this.set('currentYear', new Date().getFullYear());
    }
  });
});