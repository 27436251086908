define('whoisharrison/routes/movies/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    model() {
      return this.store.createRecord('movie');
    },

    actions: {

      saveMovie(newMovie) {
        newMovie.save().then(() => this.transitionTo('movies'));
      },

      willTransition() {
        // rollbackAttributes() removes the record from the store
        // if the model 'isNew'
        this.controller.get('model').rollbackAttributes();
      }
    }
  });
});